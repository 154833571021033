import "../App.css";
import io from "socket.io-client";
import moment from "moment-timezone";
import logo from "../assets/logo.png";
import Flag1 from "../assets/Flag1.png";
import Flag2 from "../assets/Flag2.png";
import Flag3 from "../assets/Flag3.png";
import Blocked from "../components/Blocked";
import MarketClose from "../components/MarketClose";
import bullionStats from "../assets/bullioStats.png";
import React, { useEffect, useMemo, useState } from "react";
import TradingViewChart from "../components/TradingViewChart";
import { commodityCalculation } from "../components/constants";
import EconomicNewsModal from "../components/EconomicNewsModal";
import SubscriptionExpired from "../components/SubscriptionExpired";
import { Box, LinearProgress, Modal, Typography } from "@mui/material";
import SubscriptionExpiringSoon from "../components/SubscriptionExpiringSoon";
import {
  allCommodities,
  allNewses,
  getAdminProfile,
  getEconomicNews,
  getGoldPriceNews,
  getLiveValueTypeForDisplay,
  getRatio,
  getSocketUrl,
  getSpread,
} from "../sevice/home";

const { makeStyles } = require("@mui/styles");
let socket = localStorage.getItem("socketUrl")
  ? io(JSON.parse(localStorage.getItem("socketUrl")))
  : null;

const socket2 = io(process.env.REACT_APP_BACKEND_URL, { path: "/latest" });
const adminId = process.env.REACT_APP_ADMIN_ID;

const useStyles = makeStyles((theme) => ({
  mainBody: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
  },
  mainContainer: {
    width: "100%",
    height: "95vh",
    margin: "auto",
    display: "grid",
    gridTemplateRows: "1fr 1fr",
    gridTemplateColumns: "1fr",
    gridTemplateAreas: `
        'topPart '
        'bottomPart'
      `,
    padding: "2rem 2rem 2rem 2rem ",
    boxSizing: "border-box",
    gap: 16,
  },
  topPart: {
    boxSizing: "border-box",
    gridArea: "topPart",
    width: "100%",
    height: "100%",
    display: "flex",
    gap: 10,
  },
  time: {
    border: "1px solid #4B3516",
    height: "14%",
    display: "grid",
    backgroundColor: "#000000",
    borderRadius: "10px",
    paddingLeft: "2rem",
  },
  currencyCol: {
    backgroundColor: "#04372F",
    borderRadius: "10px",
    display: "flex",
  },
  currencyTextBox: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    color: "#FFFFFF",
  },
  spotRate: {
    height: "70%",
    width: "100%",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    gap: ".6rem",
    color: "#FFFFFF",
  },
  spotRateBoxGoldRow2Col1: {
    gridArea: "spotRateBoxGoldRow2Col1",
    display: "flex",
    flexDirection: "column",
    gap: 3,
  },
  spotRateBoxGoldRow2Col2: {
    gridArea: "spotRateBoxGoldRow2Col2",
    display: "flex",
    flexDirection: "column",
    gap: 3,
  },
  spotRateBoxSilverRow2Col1: {
    gridArea: "spotRateBoxSilverRow2Col1",
    display: "flex",
    flexDirection: "column",
    gap: "0.3rem",
    alignItems: "center",
  },
  spotRateBoxSilverRow2Col2: {
    gridArea: "spotRateBoxSilverRow2Col2",
    display: "flex",
    flexDirection: "column",
    gap: "0.3rem",
    alignItems: "center",
  },
  stat: {
    height: "12%",
    gridArea: "stat",
    color: "#fff",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "8px",
  },
  bullionStatsImg: {
    width: "10vw",
    height: "auto",
  },
  bottomPart: {
    gridArea: "bottomPart",
    width: "100%",
    height: "100%",
    display: "flex",
    gap: 20,
    boxSizing: "border-box",
  },
  logoImg: {
    width: "80%",
    height: "80%",
    objectFit: "contain",
  },
  commoditieTable: {
    gridArea: "commoditieTable",
    height: "100%",
    display: "flex",
    boxSizing: "border-box",
    flexDirection: "column",
    borderRadius: "5px",
  },
  table: {
    gridArea: "table",
    gap: "20px",
    boxSizing: "border-box",
    color: "#FFFFFF",
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  tabeHeader: {
    gridArea: "tabeHeader",
    flex: "1",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "30%",
    background: "#000000",
    boxShadow: "0px 4px 4px 0px #E1BA78",
    border: "1px solid #4B3516",
    transform: "skewX(-20deg)",
    marginLeft: 16,
    marginRight: 16,
    // marginTop: 20,
  },
  tableContent: {
    gridArea: "tableContent",
    display: "flex",
    height: "70%",
    flexDirection: "column",
    gap: "12px",
    overflowY: "scroll",
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  tableRowColumn: {
    flex: "1",
    display: "flex",
    alignItems: "center",
    color: "#FFFFFF",
    fontSize: "2vw",
    transform: "skewX(20deg)",
  },
  tableRow1: {
    flexBasis: 0,
    minHeight: "28%",
    flexGrow: 1,
    gridArea: "tableRow1",
    marginLeft: 16,
    marginRight: 16,
    display: "flex",
    background: "#000000",
    boxShadow: "0px 4px 4px 0px #E1BA78",
    border: " 1px solid #4B3516",
    transform: "skewX(-20deg)",
  },
  updates: {
    padding: "0rem 0rem 1.5rem 0rem",
    display: "flex",
    height: "7%",
    color: "#FFFFFF",
  },
  updatesContent: {
    display: "flex",
    alignItems: "center",
    background: "#2F2F2F",
    width: "100%",
    borderRadius: "0px 5px 5px 0px",
  },
  blur: {
    filter: "blur(8px)",
  },
}));

const Home = () => {
  const classes = useStyles();
  const [adminData, setAdminData] = useState();
  const [openSubscriptionExpireSoon, setOpenSubscriptionExpireSoon] =
    useState(false);
  const [openSubscriptionExpierd, setOpenSubscriptionExpierd] = useState(false);
  const [openBlocked, setOpenBlocked] = useState(false);
  const [isMarketOpen, setIsMarketOpen] = useState(true);
  const [render, setRender] = useState(true);

  const [gold, setGold] = useState({
    cur: { ask: 0, bid: 0, highPrice: 0, lowPrice: 0 },
    pre: { ask: 0, bid: 0, highPrice: 0, lowPrice: 0 },
  });
  const [silver, setSilver] = useState({
    cur: { ask: 0, bid: 0, highPrice: 0, lowPrice: 0 },
    pre: { ask: 0, bid: 0, highPrice: 0, lowPrice: 0 },
  });
  const [ratio, setRatio] = useState({
    Buyers: "77%",
    chgValue: "+0.370%",
    commodity: "Gold",
  });
  const [displayBidOrBuy, setDisplayBidOrBuy] = useState({
    bidOrBuy: "Bid",
    askOrSell: "Ask",
  });
  const [spread, setSpread] = useState({
    goldAskSpread: 0,
    goldBidSpread: 0,
    silverAskSpread: 0,
    silverBidSpread: 0,
    goldLowSpread: 0,
    goldHighSpread: 0,
    silverLowSpread: 0,
    silverHighSpread: 0,
  });
  const [commodities, setCommodities] = useState([]);
  const [news, setNews] = useState([]);
  const [goldNews, setGoldNews] = useState({});

  ////////////Block//////
  const handleOpenBlocked = () => setOpenBlocked(true);
  const handleCloseBlocked = () => {
    window.location.reload();
  };
  ///////////////////// SubscriptionExpierd /////////////////
  const handleOpenSubscriptionExpierd = () => setOpenSubscriptionExpierd(true);
  const handleCloseSubscriptionExpierd = () => window.location.reload();
  ///////////////////// SubscriptionExpierSoon /////////////////////
  const handleExpireSoonOpen = () => setOpenSubscriptionExpireSoon(true);
  const handleOpenExpireSoonClose = () => setOpenSubscriptionExpireSoon(false);

  ///////////////////// Check is Market Closed  /////////////////////
  useEffect(() => {
    const interval = setInterval(() => {
      if (!adminData?.time_zone) return;
      const now = moment().tz(adminData?.time_zone);
      const dayOfWeek = now?.day();
      const hour = now.hour();
      if (
        (dayOfWeek >= 1 && dayOfWeek <= 5 && hour >= 1 && hour < 2) || // Monday to Friday, 1AM to 2AM
        (dayOfWeek === 6 && hour >= 1) || // Saturday, 1AM onwards
        dayOfWeek === 0 || // Sunday
        (dayOfWeek === 1 && hour < 2) // Monday, before 2AM
      ) {
        setIsMarketOpen(false);
      } else {
        setIsMarketOpen(true);
      }
    }, 1000); // Check every minute

    return () => clearInterval(interval);
  }, [adminData?.time_zone]);

  useMemo(() => {
    if (socket && render) {
      setRender(false);
      socket.on("connect", () => {});
      socket.on("disconnect", () => {});
      socket.on("connect_error", (err) => {
        console.log(`connect_error due to ${err.message}`);
      });
      socket.on("gold-rate-change", (data) => {
        // setIsMarketOpen(data.data.isMarketOpen);
        setGold((e) => {
          return { cur: data.data, pre: e.cur };
        });
      });
      socket.on("silver-rate-change", (data) => {
        setSilver((e) => {
          return { cur: data.data, pre: e.cur };
        });
      });
    }
  }, [socket]);

  useEffect(() => {
    socket2.on("connect", () => {});
    socket2.on("disconnect", () => {});
    socket2.emit("join_room", { roomId: adminId });
    socket2.on("commodityRerender", (data) => {
      handleFindCommodities();
    });
    socket2.on("newsRerender", (data) => {
      hadlefetchNews();
    });
    socket2.on("spreadRerender", (data) => {
      handleFindSpread();
    });
    socket2.on("adminBlockRerender", (data) => {
      window.location.reload();
      // handleFindAdminProfile();
    });
    socket2.on("socketRerender", (data) => {
      localStorage.removeItem("socketUrl");
      window.location.reload();
    });
    socket2.on("reloadRender", (data) => {
      window.location.reload();
    });
    socket2.on("upgradeRerender", (data) => {
      handleFindAdminProfile();
    });
    socket2.on("bidAndAskTitleRerender", (data) => {
      handleFindLiveValueTypeForDisplay();
    });
    socket2.on("economicNewsRender", (data) => {
      handleFindEconomicNews();
    });
  }, []);

  const handleFindCommodities = async () => {
    const res = await allCommodities();
    if (Array.isArray(res.commodities)) {
      setCommodities(res.commodities);
    }
  };
  const handleFindSpread = async () => {
    const res = await getSpread();
    if (res) {
      setSpread(res);
    }
  };
  const hadlefetchNews = async () => {
    const res = await allNewses();
    if (Array.isArray(res)) {
      setNews(res);
    }
  };
  const handleFindGoldPriceNews = async () => {
    const res = await getGoldPriceNews();
    if (res.length > 0) setGoldNews(res[0]);
  };

  const handleFindLiveValueTypeForDisplay = async () => {
    const res = await getLiveValueTypeForDisplay();
    if (res) {
      setDisplayBidOrBuy(res);
    }
  };
  const handleFindRatio = async () => {
    const res = await getRatio();
    if (res?.data) {
      setRatio(res.data);
    }
  };
  const handleFindSocketUrl = async () => {
    const res = await getSocketUrl();
    if (res) {
      socket = io(res?.socketURL);
      localStorage.setItem("socketUrl", JSON.stringify(res?.socketURL));
    }
  };

  const handleFindAdminProfile = async () => {
    const res = await getAdminProfile();
    setAdminData(res);
    if (!res.status || res.isBlocked) {
      handleOpenBlocked();
    } else if (!res.isBlocked && openBlocked) {
    } else {
      handlecheckSubscriptionExpierd(res);
    }
  };

  const handlecheckSubscriptionExpierd = (data) => {
    const givenDate = new Date(data?.package.expire_date);
    let a = givenDate.toISOString();
    let b = new Date().toISOString();
    if (a < b) {
      handleOpenSubscriptionExpierd();
    }
  };

  useEffect(() => {
    if (adminData) {
      const interval = setInterval(() => {
        checkSubscriptionExpireSoon(adminData);
      }, 60 * 60 * 1000);
      return () => clearInterval(interval);
    }
  }, [adminData]);

  const checkSubscriptionExpireSoon = (data) => {
    const givenDate = new Date(data?.package.expire_date);
    const oneMonthAgo = new Date(givenDate);
    oneMonthAgo.setDate(givenDate.getDate() - 30);
    let a = oneMonthAgo.toISOString();
    let b = new Date().toISOString();
    if (b >= a) {
      const currentDate = new Date();
      const currentHour = currentDate.getHours();
      if (currentHour === 10) {
        handleExpireSoonOpen();
      }
    }
  };

  useEffect(() => {
    handleFindCommodities();
    handleFindSpread();
    hadlefetchNews();
    handleFindLiveValueTypeForDisplay();
    handleFindRatio();
    handleFindSocketUrl();
    handleFindAdminProfile();
    handleFindEconomicNews();
    handleFindGoldPriceNews();
  }, []);

  const [economicNews, setEconomicNews] = useState([]);
  const [openNews, setOpenNews] = useState(false);
  const handleOpenopenNews = () => setOpenNews(true);
  const handleCloseopenNews = () => setOpenNews(false);

  const [curEconomicNews, setCurEconomicNews] = useState([]);
  const handleFindEconomicNews = async () => {
    const res = await getEconomicNews();
    setEconomicNews(res);
  };

  useEffect(() => {
    const timeInterwell = setInterval(() => {
      economicNewsFinding();
    }, 1000 * 60 * 1);
    economicNewsFinding();
    return () => clearInterval(timeInterwell);
  }, [economicNews]);

  const economicNewsFinding = () => {
    if (economicNews.length) {
      const currentTime = new Date(new Date().toUTCString());
      const oneHourAgo = new Date(currentTime);
      oneHourAgo.setHours(currentTime.getHours() + 1);
      oneHourAgo.setSeconds(0);
      oneHourAgo.setMilliseconds(0);

      const currentTimeMin = new Date(new Date().toUTCString());
      const fiveMinAgo = new Date(currentTimeMin);
      fiveMinAgo.setMinutes(currentTimeMin.getMinutes());
      fiveMinAgo.setSeconds(0);
      fiveMinAgo.setMilliseconds(0);

      let objectBeforeFiveMin = economicNews.filter((obj) => {
        let utcDate = new Date(obj.date);
        let timeDiff =
          new Date(
            utcDate?.toLocaleString("en-US", { timeZone: "UTC" })
          ).getTime() - fiveMinAgo.getTime();

        let minutesDiff = Math.floor(timeDiff / (1000 * 60));
        return minutesDiff <= 5 && minutesDiff >= 0;
      });
      let objectBeforeOneHour = economicNews.filter((obj) => {
        let utcDate = new Date(obj.date);
        let timeDiff =
          new Date(
            utcDate?.toLocaleString("en-US", { timeZone: "UTC" })
          ).getTime() - oneHourAgo.getTime();

        let minutesDiff = Math.floor(timeDiff / (1000 * 60));
        return minutesDiff <= 5 && minutesDiff >= -5;
      });
      if (objectBeforeFiveMin.length) {
        objectBeforeFiveMin = objectBeforeFiveMin?.sort(
          (a, b) => b.priority - a.priority
        );
        setCurEconomicNews(objectBeforeFiveMin);
        handleOpenopenNews();
      } else if (objectBeforeOneHour.length) {
        objectBeforeOneHour = objectBeforeOneHour?.sort(
          (a, b) => b.priority - a.priority
        );
        setCurEconomicNews(objectBeforeOneHour);
        handleOpenopenNews();
      } else {
        setCurEconomicNews([]);
        handleCloseopenNews();
      }
    } else {
      setCurEconomicNews([]);
    }
  };

  const buyersColor = "#2FB545";
  const sellersColor = "#D1172D";

  const progress = Number(ratio?.Buyers?.match(/\d+/)[0]);
  const progressStyle = { backgroundColor: sellersColor };
  const barStyle = { backgroundColor: buyersColor };

  const formatDate = () => moment(new Date()).format("DD MMM YYYY");
  const formatday = () => moment(new Date()).format("dddd");

  return (
    <div
      style={{ width: "100dvw", height: "100dvh", background: "#000000" }}
      className={openSubscriptionExpierd && classes.blur}
    >
      <Box className={classes.mainBody}>
        <Box className={classes.mainContainer}>
          {!isMarketOpen && <MarketClose timeZone={adminData?.time_zone} />}
          <Box className={classes.topPart}>
            <Box
              sx={{
                height: "100%",
                width: "20%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img className={classes.logoImg} src={logo} alt="leenLogo" />
            </Box>
            <Box sx={{ height: "100%", width: "45%" }}>
              <TradingViewChart symbol={"XAUUSD"} />
            </Box>
            <Box
              sx={{
                height: "100%",
                width: "35%",
                display: "flex",
                flexDirection: "column",
                gap: 2,
              }}
            >
              <Box
                sx={{
                  color: "white",
                  height: "30%",
                  width: "auto",
                  border: "1px solid #4B3516",
                  display: "grid",
                  backgroundColor: "#000000",
                  borderRadius: "10px",
                  paddingLeft: 2,
                }}
              >
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      mr: "20px",
                      gap: "10px",
                      // width: "26%",
                      justifyContent: "end",
                    }}
                  >
                    <img
                      src={Flag1}
                      alt="f"
                      srcset=""
                      style={{ height: "30px" }}
                    />
                    <Box>
                      <Typography
                        sx={{
                          fontSize: {
                            lg: "1.3vw",
                            md: "1.2vw",
                            sm: "1vw",
                          },
                          color: "#A9A9A9",
                          // letterSpacing: "2px",
                        }}
                      >
                        {moment(Date.now()).tz("Asia/Dubai").format("hh:mm A")}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      mr: "20px",
                      gap: "10px",
                      // width: "26%",
                      justifyContent: "end",
                    }}
                  >
                    <img
                      src={Flag2}
                      alt="f"
                      srcset=""
                      style={{ height: "30px" }}
                    />
                    <Box>
                      <Typography
                        sx={{
                          fontSize: {
                            lg: "1.3vw",
                            md: "1.2vw",
                            sm: "1vw",
                          },
                          color: "#A9A9A9",
                          // letterSpacing: "2px",
                        }}
                      >
                        {moment(Date.now())
                          .tz("Europe/London")
                          .format("hh:mm A")}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      mr: "15px",
                      gap: "10px",
                      // width: "26%",
                      justifyContent: "end",
                    }}
                  >
                    <img
                      src={Flag3}
                      alt="f"
                      srcset=""
                      style={{ height: "30px" }}
                    />
                    <Box>
                      <Typography
                        sx={{
                          fontSize: {
                            lg: "1.3vw",
                            md: "1.2vw",
                            sm: "1vw",
                          },
                          color: "#A9A9A9",
                          // letterSpacing: "2px",
                        }}
                      >
                        {moment(Date.now())
                          .tz("America/New_York")
                          .format("hh:mm A")}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Typography
                  sx={{
                    fontSize: "1.3vw",
                    letterSpacing: "5px",
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                  }}
                >
                  {formatday().toUpperCase()}&nbsp;-&nbsp;
                  {formatDate().toUpperCase()}
                </Typography>
              </Box>

              <Box className={classes.spotRate}>
                <Box
                  sx={{
                    boxShadow: "0px 4px 4px 0px #E1BA7840",
                    border: "1px solid #4B3516",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "#000000",
                    borderRadius: "8px",
                    height: "100%",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "2vw",
                      fontWeight: "bold",
                      p: 0,
                      flexBasis: 0,
                      flexGrow: 0.8,
                      flexShrink: 1,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "2vw",
                        fontWeight: "bold",
                        flexBasis: 0,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: -2.5,
                      }}
                    >
                      GOLD
                    </Typography>
                    Oz
                  </Typography>
                  <Box
                    sx={{ display: "flex", flexDirection: "column", gap: 2 }}
                  >
                    <Box className={classes.spotRateBoxGoldRow2Col1}>
                      <Box
                        sx={{
                          width: "12vw",
                          display: "flex",
                          border: "1px solid #E1BA78",
                          background: "#E1BA78",
                          borderRadius: "10px",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          sx={{
                            width: "2vw",
                            height: "7vh",
                            display: "flex",
                          }}
                        >
                          <Typography
                            sx={{
                              writingMode: "vertical-rl",
                              textOrientation: "mixed",
                              transform: "rotate(180deg)",
                              color: "black",
                              textAlign: "center",
                              fontSize: "1.2vw",
                              fontWeight: "bold",
                            }}
                          >
                            {displayBidOrBuy?.bidOrBuy.toUpperCase()}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            backgroundColor:
                              Number(gold?.cur?.bid).toFixed(2) <
                              Number(gold?.pre?.bid).toFixed(2)
                                ? "#D1172D"
                                : Number(gold?.cur?.bid).toFixed(2) >
                                  Number(gold?.pre?.bid).toFixed(2)
                                ? "#2FB545"
                                : "#000000",
                            color: "#FFFFFF",
                            borderRadius: "10px",
                            width: "10vw",
                            height: "8vh",
                            display: "flex",
                            alignItems: "center",
                            px: 1.5,
                            transition: "background-color .5s ease",
                            justifyContent: "center",
                          }}
                        >
                          <Typography
                            sx={{ fontSize: "2.2vw", fontWeight: "bold" }}
                          >
                            {(!gold.cur.bid
                              ? 0
                              : Number(spread.goldBidSpread) +
                                Number(gold?.cur?.bid)
                            ).toFixed(2)}
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          gap: "5px",
                          alignItems: "center",
                          ml: 7,
                        }}
                      >
                        <Typography
                          style={{ fontSize: "1vw", color: "#2FB545" }}
                        >
                          HIGH
                        </Typography>
                        &nbsp;
                        <Typography style={{ fontSize: "1vw" }}>
                          {(
                            Number(spread.goldHighSpread) +
                            Number(gold?.cur?.highPrice)
                          ).toFixed(2)}
                        </Typography>
                      </Box>
                    </Box>

                    <Box className={classes.spotRateBoxGoldRow2Col2}>
                      <Box
                        sx={{
                          width: "12vw",
                          display: "flex",
                          border: "1px solid #E1BA78",
                          background: "#E1BA78",
                          borderRadius: "10px",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          sx={{
                            width: "2vw",
                            height: "7vh",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <Typography
                            sx={{
                              writingMode: "vertical-rl",
                              textOrientation: "mixed",
                              transform: "rotate(180deg)",
                              color: "black",
                              textAlign: "center",
                              fontSize: "1.2vw",
                              fontWeight: "bold",
                            }}
                          >
                            {displayBidOrBuy?.askOrSell.toUpperCase()}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            backgroundColor:
                              Number(gold?.cur?.ask).toFixed(2) <
                              Number(gold?.pre?.ask).toFixed(2)
                                ? "#D1172D"
                                : Number(gold?.cur?.ask).toFixed(2) >
                                  Number(gold?.pre?.ask).toFixed(2)
                                ? "#2FB545"
                                : "#000000",
                            color: "#FFFFFF",
                            borderRadius: "10px",
                            width: "10vw",
                            height: "8vh",
                            display: "flex",
                            alignItems: "center",
                            px: 1.5,
                            transition: "background-color .5s ease",
                            justifyContent: "center",
                          }}
                        >
                          <Typography
                            sx={{ fontSize: "2.2vw", fontWeight: "bold" }}
                          >
                            {(!gold.cur.bid
                              ? 0
                              : Number(spread.goldAskSpread) +
                                Number(gold?.cur?.ask)
                            ).toFixed(2)}
                          </Typography>
                        </Box>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          gap: "5px",
                          alignItems: "center",
                          ml: 7,
                        }}
                      >
                        <Typography
                          style={{ fontSize: "1vw", color: "#D1172D" }}
                        >
                          LOW
                        </Typography>
                        &nbsp;
                        <Typography style={{ fontSize: "1vw" }}>
                          {(!gold.cur.bid
                            ? 0
                            : Number(spread.goldLowSpread) +
                              Number(gold?.cur?.lowPrice)
                          ).toFixed(2)}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className={classes.bottomPart}>
            <Box sx={{ height: "100%", width: "65%" }}>
              <Box className={classes.commoditieTable}>
                <Box className={classes.table}>
                  <Box className={classes.tabeHeader}>
                    <Box
                      sx={{
                        flex: 1,
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        sx={{
                          flex: 1,
                          display: "flex",
                          justifyContent: "left",
                          fontSize: "1.8vw",
                          fontWeight: "bold",
                          transform: "skewX(20deg)",
                          ml: 4,
                        }}
                      >
                        COMMODITY
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        flex: 1,
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          flex: 1,
                          fontSize: "1.8vw",
                          fontWeight: "bold",
                          transform: "skewX(20deg)",
                        }}
                      >
                        WEIGHT
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        flex: 1,
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        sx={{
                          flex: 1,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "1.8vw",
                          fontWeight: "bold",
                          transform: "skewX(20deg)",
                        }}
                      >
                        {displayBidOrBuy?.bidOrBuy.toUpperCase()}
                        <Typography
                          sx={{
                            fontSize: "1vw",
                            ml: 0.5,
                            mt: 1,
                          }}
                        >
                          &nbsp;AED&nbsp;
                        </Typography>
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        flex: 1,
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        sx={{
                          flex: 1,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "1.8vw",
                          fontWeight: "bold",
                          transform: "skewX(20deg)",
                        }}
                      >
                        {displayBidOrBuy?.askOrSell.toUpperCase()}
                        <Typography
                          sx={{
                            fontSize: "1vw",
                            ml: 0.5,
                            mt: 1,
                          }}
                        >
                          &nbsp;AED&nbsp;
                        </Typography>
                      </Typography>
                    </Box>
                  </Box>
                  <Box className={classes.tableContent}>
                    {commodities?.map((commodity, idx) => {
                      const words = commodity.commodity_title.split(" ");
                      return (
                        <Box
                          key={idx}
                          className={classes.tableRow1}
                          sx={{
                            maxHeight: commodities.length > 6 ? "17%" : "",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "1.3vw",
                              fontWeight: "bold",
                              justifyContent: "left",
                              transform: "skewX(20deg)",
                              ml: 3,
                            }}
                            className={classes.tableRowColumn}
                          >
                            {words?.map((word, index) => {
                              if (index === 0) {
                                return (
                                  <span
                                    key={index}
                                    style={{
                                      fontSize: "2vw",
                                      fontWeight: "bold",
                                      paddingLeft: "15px",
                                    }}
                                  >
                                    {word.toUpperCase()}
                                  </span>
                                );
                              } else {
                                return (
                                  <span
                                    key={index}
                                    style={{
                                      fontSize: "1vw",
                                      marginTop: "0.2rem",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    &nbsp;
                                    {word.toUpperCase()}
                                  </span>
                                );
                              }
                            })}
                            &nbsp;
                            {commodity.unitLabel === "TTB"
                              ? ""
                              : commodity.displayPurity}
                          </Typography>
                          <Box className={classes.tableRowColumn}>
                            <Typography
                              style={{ fontSize: "2vw", fontWeight: "bold" }}
                              sx={{
                                paddingLeft: { lg: "3.7vw", md: "3.2vw" },
                              }}
                            >
                              {commodity?.unit}
                              <span style={{ fontSize: "2vw" }}>
                                &nbsp;
                                {commodity?.unitLabel}
                              </span>
                            </Typography>
                          </Box>
                          <Box
                            className={classes.tableRowColumn}
                            sx={{
                              justifyContent: "left",
                            }}
                          >
                            <Typography
                              style={{
                                fontSize: "2vw",
                                fontWeight: "bold",
                              }}
                              sx={{
                                paddingLeft: { lg: "3.8vw", md: "2.9vw" },
                              }}
                            >
                              {!gold?.cur?.bid
                                ? "0.00"
                                : commodityCalculation(
                                    commodity.commodity_title === "Silver"
                                      ? silver?.cur?.bid
                                      : gold?.cur?.bid,
                                    commodity.commodity_title === "Silver"
                                      ? spread.silverBidSpread
                                      : spread.goldBidSpread,
                                    commodity.buy_premium,
                                    3.674,
                                    commodity.purity,
                                    commodity.unit,
                                    commodity.unitLabel === "TTB"
                                      ? 116.64
                                      : commodity.unitLabel === "KG"
                                      ? 1000
                                      : commodity.unitLabel === "OZ"
                                      ? 31.1
                                      : commodity.unitLabel === "TOLA"
                                      ? 11.7
                                      : 1,
                                    commodity.buy_charge
                                  )}
                            </Typography>
                          </Box>
                          <Box
                            className={classes.tableRowColumn}
                            sx={{
                              justifyContent: "left",
                            }}
                          >
                            <Typography
                              style={{ fontSize: "2vw", fontWeight: "bold" }}
                              sx={{
                                paddingLeft: { lg: "3.9vw", md: "2.9vw" },
                              }}
                            >
                              {!gold?.cur?.bid
                                ? "0.00"
                                : commodityCalculation(
                                    commodity.commodity_title === "Silver"
                                      ? silver?.cur?.ask
                                      : gold?.cur?.ask,
                                    commodity.commodity_title === "Silver"
                                      ? spread.silverAskSpread
                                      : spread.goldAskSpread,
                                    commodity.premium,
                                    3.674,
                                    commodity.purity,
                                    commodity.unit,
                                    commodity.unitLabel === "TTB"
                                      ? 116.64
                                      : commodity.unitLabel === "KG"
                                      ? 1000
                                      : commodity.unitLabel === "OZ"
                                      ? 31.1
                                      : commodity.unitLabel === "TOLA"
                                      ? 11.7
                                      : 1,
                                    commodity.charges
                                  )}
                            </Typography>
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                height: "100%",
                width: "35%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 2,
              }}
            >
              <Box
                sx={{
                  height: "70%",
                  width: "100%",
                  boxSizing: "border-box",
                  display: "flex",
                  flexDirection: "column",
                  color: "#FFFFFF",
                }}
              >
                <Box
                  sx={{
                    boxShadow: "0px 4px 4px 0px #E1BA7840",
                    border: "1px solid #4B3516",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "#000000",
                    borderRadius: "8px",
                    height: "100%",
                    width: "100%",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "2vw",
                      fontWeight: "bold",
                      p: 0,
                      flexBasis: 0,
                      flexGrow: 0.8,
                      flexShrink: 1,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "2vw",
                        fontWeight: "bold",
                        flexBasis: 0,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: -2.5,
                      }}
                    >
                      SILVER
                    </Typography>
                    Oz
                  </Typography>
                  <Box
                    sx={{ display: "flex", flexDirection: "column", gap: 2 }}
                  >
                    <Box className={classes.spotRateBoxGoldRow2Col1}>
                      <Box
                        sx={{
                          width: "12vw",
                          display: "flex",
                          border: "1px solid #CDCDCD",
                          background: "#CDCDCD",
                          borderRadius: "10px",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          sx={{
                            width: "2vw",
                            height: "7vh",
                            display: "flex",
                          }}
                        >
                          <Typography
                            sx={{
                              writingMode: "vertical-rl",
                              textOrientation: "mixed",
                              transform: "rotate(180deg)",
                              color: "black",
                              textAlign: "center",
                              fontSize: "1.2vw",
                              fontWeight: "bold",
                            }}
                          >
                            {displayBidOrBuy?.bidOrBuy.toUpperCase()}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            backgroundColor:
                              Number(silver?.cur?.bid) <
                              Number(silver?.pre?.bid)
                                ? "#D1172D"
                                : Number(silver?.cur?.bid) >
                                  Number(silver?.pre?.bid)
                                ? "#2FB545"
                                : "#000000",
                            color: "#FFFFFF",
                            borderRadius: "10px",
                            width: "10vw",
                            height: "8vh",
                            display: "flex",
                            alignItems: "center",
                            px: 1.5,
                            transition: "background-color .5s ease",
                            justifyContent: "center",
                          }}
                        >
                          <Typography
                            sx={{ fontSize: "2.2vw", fontWeight: "bold" }}
                          >
                            {(!gold.cur.bid
                              ? 0
                              : Number(spread.silverBidSpread) +
                                Number(silver?.cur?.bid)
                            ).toFixed(3)}
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          gap: "5px",
                          alignItems: "center",
                          ml: 7,
                        }}
                      >
                        <Typography
                          style={{ fontSize: "1vw", color: "#2FB545" }}
                        >
                          HIGH
                        </Typography>
                        &nbsp;
                        <Typography style={{ fontSize: "1vw" }}>
                          {(!gold.cur.bid
                            ? 0
                            : Number(spread.silverHighSpread) +
                              Number(silver?.cur?.highPrice)
                          ).toFixed(3)}
                        </Typography>
                      </Box>
                    </Box>

                    <Box className={classes.spotRateBoxGoldRow2Col2}>
                      <Box
                        sx={{
                          width: "12vw",
                          display: "flex",
                          border: "1px solid #CDCDCD",
                          background: "#CDCDCD",
                          borderRadius: "10px",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          sx={{
                            width: "2vw",
                            height: "7vh",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <Typography
                            sx={{
                              writingMode: "vertical-rl",
                              textOrientation: "mixed",
                              transform: "rotate(180deg)",
                              color: "black",
                              textAlign: "center",
                              fontSize: "1.2vw",
                              fontWeight: "bold",
                            }}
                          >
                            {displayBidOrBuy?.askOrSell.toUpperCase()}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            backgroundColor:
                              Number(silver?.cur?.ask) <
                              Number(silver?.pre?.ask)
                                ? "#D1172D"
                                : Number(silver?.cur?.ask) >
                                  Number(silver?.pre?.ask)
                                ? "#2FB545"
                                : "#000000",

                            color: "#FFFFFF",
                            borderRadius: "10px",
                            width: "10vw",
                            height: "8vh",
                            display: "flex",
                            alignItems: "center",
                            px: 1.5,
                            transition: "background-color .5s ease",
                            justifyContent: "center",
                          }}
                        >
                          <Typography
                            sx={{ fontSize: "2.2vw", fontWeight: "bold" }}
                          >
                            {(!gold.cur.bid
                              ? 0
                              : Number(spread.silverAskSpread) +
                                Number(silver?.cur?.ask)
                            ).toFixed(3)}
                          </Typography>
                        </Box>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          gap: "5px",
                          alignItems: "center",
                          ml: 7,
                        }}
                      >
                        <Typography
                          style={{ fontSize: "1vw", color: "#D1172D" }}
                        >
                          LOW
                        </Typography>
                        &nbsp;
                        <Typography style={{ fontSize: "1vw" }}>
                          {(!gold.cur.bid
                            ? 0
                            : Number(spread.silverLowSpread) +
                              Number(silver?.cur?.lowPrice)
                          ).toFixed(3)}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  height: "30%",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  background: "#000000",
                  boxShadow: "0px 4px 4px 0px #E1BA7840",
                  border: "1px solid #4B3516",
                  borderRadius: "8px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "95%",
                    px: 1,
                    py: 1,
                    boxSizing: "border-box",
                  }}
                >
                  <Typography sx={{ fontSize: ".9vw", color: "white" }}>
                    BUYERS
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "1vw",
                      color:
                        Number(ratio?.chgValue.replace("%", "")) >= 0
                          ? "#2FB545"
                          : "#D1172D",
                    }}
                  >
                    {ratio?.chgValue}
                  </Typography>
                  <Typography sx={{ fontSize: ".9vw", color: "white" }}>
                    SELLERS
                  </Typography>
                </Box>
                <Box sx={{ width: "95%" }}>
                  <LinearProgress
                    variant="determinate"
                    value={progress}
                    style={progressStyle}
                    sx={{
                      "& .MuiLinearProgress-bar": barStyle,
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "95%",
                    // px: 7,
                    // py: 1,
                    boxSizing: "border-box",
                  }}
                >
                  <Typography sx={{ fontSize: ".9vw", color: "#2FB545" }}>
                    {ratio?.Buyers}
                  </Typography>
                  <Box
                    sx={{
                      height: "auto",
                      display: "flex",
                      // pb: 3,
                      mt: 1,
                      justifyContent: "center",
                    }}
                  >
                    <a href="https://www.bullionstats.com/">
                      <img
                        className={classes.bullionStatsImg}
                        src={bullionStats}
                        alt="bullionStats"
                      />
                    </a>
                  </Box>
                  <Typography sx={{ fontSize: "1vw", color: "#D1172D" }}>
                    {100 - Number(ratio?.Buyers?.match(/\d+/)[0])}%
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className={classes.updates}>
          <Box className={classes.updatesContent} sx={{ minHeight: "100%" }}>
            <marquee
              style={{
                height: "60%",
                display: "flex",
                alignItems: "center",
                width: "100%",
              }}
              direction="right"
            >
              <Box sx={{ display: "flex" }}>
                {!adminData?.isNewsEnable &&
                Object.keys(goldNews).length > 0 ? (
                  <Box
                    style={{
                      fontSize: "1.5vw",
                      fontFamily: "poppins",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <span style={{ fontWeight: "bold" }}>Gold Price News</span>
                    &nbsp;&nbsp;:&nbsp;&nbsp;<span>{goldNews?.title}</span>
                  </Box>
                ) : (
                  news?.map((item, index) => {
                    return (
                      <Box
                        key={item._id}
                        style={{
                          fontSize: "1.5vw",
                          fontFamily: "poppins",
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        <span
                          style={{ paddingRight: "50px", paddingLeft: "50px" }}
                        >
                          {index === news.length - 1 ? "" : "|"}
                        </span>
                        <span>{item.newsDetails}</span>&nbsp;&nbsp;:&nbsp;&nbsp;
                        <span style={{ fontWeight: "bold" }}>
                          {item.newsTitle}
                        </span>
                      </Box>
                    );
                  })
                )}
              </Box>
            </marquee>
          </Box>
        </Box>
      </Box>
      <Modal open={openBlocked} closeAfterTransition>
        <Blocked handleClose={handleCloseBlocked} />
      </Modal>
      <Modal open={openSubscriptionExpierd} closeAfterTransition>
        <SubscriptionExpired handleClose={handleCloseSubscriptionExpierd} />
      </Modal>
      <Modal
        open={openSubscriptionExpireSoon}
        onClose={handleOpenExpireSoonClose}
        closeAfterTransition
        BackdropProps={{
          style: {
            backgroundColor: "rgba(0, 0, 0, 0.1)", // Adjust the color and opacity as needed
            backdropFilter: "none", // This disables the blur effect
          },
        }}
      >
        <SubscriptionExpiringSoon
          date={adminData?.package.expire_date}
          handleClose={handleOpenExpireSoonClose}
        />
      </Modal>
      <Modal
        open={openNews}
        onClose={handleCloseopenNews}
        closeAfterTransition
        hideBackdrop
      >
        <EconomicNewsModal
          handleClose={handleCloseopenNews}
          curEconomicNews={curEconomicNews}
        />
      </Modal>
    </div>
  );
};

export default Home;
